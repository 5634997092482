import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import Notifications from 'vue-notification'
import VueMultiRef from 'vue-multi-ref'
import VueSocketIOExt from 'vue-socket.io-extended'
import io from 'socket.io-client'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// custom plugins
import FilesPlugin from './plugins/files'

// Axios Mock Adapter
import '@/@fake-db/db'

// Websockets
const socket = io(process.env.VUE_APP_BASE_WEBSOCKET_URL)
socket.on('connect_error', err => {
	console.log(err.message)
})
socket.on('connect', err => {
	console.log('connected to server')
})
socket.on('disconnect', err => {
	console.log('disconnected to server')
})
console.log(socket)
Vue.use(VueSocketIOExt, socket, { store })

// files plugin
Vue.use(FilesPlugin, { store, router })

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(Notifications)

// multi references
Vue.use(VueMultiRef)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
	router,
	store,
	i18n,
	render: h => h(App),
}).$mount('#app')
