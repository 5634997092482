import httpClient from './httpClient'

const END_POINT = process.env.VUE_APP_BASE_URL + '/external-products'

const getAll = () => {
	return httpClient.get(END_POINT, {})
}

const getByCategory = data => {
	return httpClient.post(END_POINT, { data })
}

const getCategories = () => {
	return httpClient.get(END_POINT + '/categories')
}

const getBySearchWord = searchWord => {
	return httpClient.post(END_POINT + '/search/', { searchWord })
}

const getCategoriesSS = async () => {
	const result = await httpClient.get(END_POINT + '/stanley-stella/get-categories')
	return result.result
}

const getByCategorySS = async data => {
	const result = await httpClient.get(END_POINT + `/stanley-stella/category/${data.category}`)
	return result.result
}

const getBySearchWordSS = async (searchWord, byCode = false) => {
	let result
	if (byCode) result = await httpClient.get(END_POINT + `/stanley-stella/search/${searchWord}/${byCode}`)
	else result = await httpClient.get(END_POINT + `/stanley-stella/search/${searchWord}`)
	return result.result
}

export { getAll, getByCategory, getCategories, getCategoriesSS, getByCategorySS, getBySearchWord, getBySearchWordSS }
