import Vue from 'vue'

import { getField, updateField } from 'vuex-map-fields'
import {
	getAll,
	getById,
	getByCustomer,
	update,
	create,
	addNote,
	deleteEntry,
	updatePrices,
	addMessage,
	calculateCosts,
	calculateVariantCosts,
	updateCosts,
	createOffer as createOfferApi,
	resetValues,
	assignUser,
} from '@/api/requests.api'

import { extractColors, fetchFilesByRequest } from '@/api/utils.api'

const state = {
	requests: [],
	files: [],
	currentRequest: null,
	savingRequest: false,
	tasks: [],
	taskValidation: [],
	requestValidation: false,
}

const getters = {
	getField,
	getTasks: (state) => {
		return state.tasks
	},
	getTaskValidation: (state) => {
		return state.taskValidation
	},
	getRequestValidation: (state) => {
		return state.requestValidation
	},
	getSavingState: (state) => {
		return state.savingRequest
	},
	getAllRequests: (state) => {
		return state.requests
	},
	getRequests: (state) => (status) => {
		return status ? state.requests.filter((r) => r.status === status) : state.requests
	},
	getRequestById: (state) => (id) => {
		return state.requests.find((r) => r._id.toString() === id.toString())
	},
	getRequestsByStatusArray: (state) => (statusArray) => {
		return statusArray ? state.requests.filter((r) => statusArray.indexOf(r.status) > -1) : state.requests
	},
	getCurrentRequest: function (state) {
		return state.currentRequest
	},
	getCustomerRequests:
		(state) =>
		(customerId, group = 'all') => {
			let requests = []

			const negotiationStatus = ['PENDING', 'WAITING', 'CUSTOMER-WAITING', 'ACCEPTED']
			const productionStatus = ['PRE-PRODUCTION', 'PRODUCTION', 'ON-HOLD', 'RELEASE-CUSTOMER', 'POST-PRODUCTION', 'SHIPPED']
			const completedStatus = ['COMPLETED', 'CANCELED', 'CUSTOMER-CANCELED']

			if (group === 'all') requests = state.requests.filter((r) => r.customerId === customerId)
			else if (group === 'negotiation')
				requests = state.requests.filter((r) => r.customerId === customerId && negotiationStatus.indexOf(r.status) > -1)
			else if (group === 'production')
				requests = state.requests.filter((r) => r.customerId === customerId && productionStatus.indexOf(r.status) > -1)
			else if (group === 'completed')
				requests = state.requests.filter((r) => r.customerId === customerId && completedStatus.indexOf(r.status) > -1)

			return requests
		},
}

const actions = {
	startSaving: function ({ commit }) {
		commit('SET_SAVING', true)
	},
	stopSaving: function ({ commit }) {
		commit('SET_SAVING', false)
	},
	fetchRequests: async function ({ commit }) {
		try {
			const response = await getAll()
			commit('SET_REQUESTS', response.data)
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	// fetchRequestById: async function({ commit }, id) {
	// 	try {
	// 		const response = await getById(id)
	// 		commit('SET_CURRENT_REQUEST', response.data)
	// 	} catch (error) {
	// 		Vue.notify({
	// 			group: 'main',
	// 			title: 'Server Error',
	// 			text: error.message,
	// 			type: 'error',
	// 		})
	// 	}
	// },
	fetchCustomerRequests: async function ({ commit }, customerId) {
		try {
			const response = await getByCustomer(customerId)
			commit('SET_REQUESTS', response.data)
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	update: async function ({ commit }, { id, data }) {
		try {
			const response = await update(id, data)
			commit('UPDATE_REQUEST', { payload: response.data })
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	addNote: async function ({ commit }, { requestId, text }) {
		try {
			// const user = JSON.parse(localStorage.getItem('userData'))
			// commit('ADD_TIMELINE_ENTRY', { text, user, type: 'internal-note' })
			const response = await addNote(requestId, { text })
			commit('UPDATE_REQUEST', { payload: response.data })
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	deleteEntry: async function ({ commit }, { requestId, data }) {
		try {
			// commit('REMOVE_TIMELINE_ENTRY', data._id)
			const response = await deleteEntry(requestId, data)
			commit('UPDATE_REQUEST', { payload: response.data })
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	addMessage: async function ({ commit }, { requestId, text }) {
		try {
			// const user = JSON.parse(localStorage.getItem('userData'))
			// commit('ADD_TIMELINE_ENTRY', { text, user, type: 'message' })
			const response = await addMessage(requestId, { text })
			commit('UPDATE_REQUEST', { payload: response.updated })
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	create: async function ({ commit }, data) {
		try {
			const response = await create(data)
			commit('NEW_REQUEST', response.data)
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	async fetchRequestFiles({ commit }, requestId) {
		const res = await fetchFilesByRequest(requestId)
		console.log(res)
		commit('SET_FILES', res.files)
	},
	setCurrentRequest({ commit }, request) {
		commit('SET_CURRENT_REQUEST', request)
		commit('VALIDATE_TASKS')
	},
	resetCurrentRequest({ commit }) {
		commit('SET_CURRENT_REQUEST', null)
		commit('VALIDATE_TASKS')
	},
	socket_newRequest({ commit, dispatch }, data) {
		commit('NEW_REQUEST', data)
		if (data && data._id) dispatch('fetchRequestFiles', data._id)
	},
	socket_updateRequest({ commit, dispatch }, data) {
		commit('UPDATE_REQUEST', { payload: data })
		if (data && data._id) dispatch('fetchRequestFiles', data._id)
	},
	async updatePrices({ commit }, { id, data }) {
		try {
			const result = await updatePrices(id, data)
			commit('UPDATE_REQUEST', { payload: result.updated })
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	async calculateCosts({ commit }, { requestId }) {
		try {
			const result = await calculateCosts(requestId)
			commit('UPDATE_REQUEST', { payload: result.updated })
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	async calculateVariantCosts({ commit }, { requestId, configIdx, variantIdx }) {
		try {
			const result = await calculateVariantCosts(requestId, { configIdx, variantIdx })
			console.log(result.updated)
			commit('UPDATE_REQUEST', { payload: result.updated })
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	async updateCosts({ commit }, { requestId, data, configIdx, variantIdx }) {
		try {
			const result = await updateCosts(requestId, { data, configIdx, variantIdx })
			commit('UPDATE_REQUEST', { payload: result.updated })
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	async createOffer({ commit }, { requestId }) {
		try {
			const result = await createOfferApi(requestId)
			commit('UPDATE_REQUEST', { payload: result.updated })
		} catch (error) {
			throw new Error(error)
		}
	},
	async resetValues({ commit }, { requestId }) {
		try {
			const result = await resetValues(requestId)
			commit('UPDATE_REQUEST', { payload: result.updated })
		} catch (error) {
			throw new Error(error)
		}
	},
	async checkTask({ commit, state }, { type, variantIdx, finishIdx, configurationIdx }) {
		await commit('CHECK_TASK', { type, variantIdx, finishIdx, configurationIdx })
		const response = await update(state.currentRequest._id, { checkedTasks: state.tasks })
		commit('UPDATE_REQUEST', { payload: response.data, key: 'checkedTasks' })
		commit('VALIDATE_TASKS')
	},
	async uncheckTask({ commit }, { type, variantIdx, finishIdx, configurationIdx }) {
		await commit('UNCHECK_TASK', { type, variantIdx, finishIdx, configurationIdx })
		const response = await update(state.currentRequest._id, { checkedTasks: state.tasks })
		commit('UPDATE_REQUEST', { payload: response.data, key: 'checkedTasks' })
		commit('VALIDATE_TASKS')
	},
	async extractColors({ state }, imageUrl) {
		if (imageUrl) {
			return extractColors(imageUrl).then((colors) => {
				return colors
			})
		} else {
			return false
		}
	},
	async assignUser({ commit }, { requestId, employee }) {
		const response = await assignUser(state.currentRequest._id, employee)
		commit('UPDATE_REQUEST', { payload: response.updated, key: 'assignedUser' })
		commit('UPDATE_REQUEST', { payload: response.updated, key: 'timeline' })
	},
}

const mutations = {
	updateField,
	SET_SAVING: function (state, payload) {
		state.savingRequest = payload
	},
	SET_REQUESTS: function (state, payload) {
		if (payload) {
			if (state.requests && state.requests.length > 0) state.requests.concat(payload)
			else state.requests = payload
		}
	},
	NEW_REQUEST(state, payload) {
		console.log('NEW REQUEST')
		console.log(payload)
		if (payload) {
			const requests = [...state.requests]
			requests.push(payload)
			state.requests = [...requests]
		}
	},
	UPDATE_REQUEST(state, { payload, key }) {
		if (key) {
			// update only key
			state.requests.forEach((r) => {
				if (r._id === payload._id) {
					r[key] = payload[key]
				}
			})
		} else {
			// update all requests array, this helps triggering UI changes
			const requests = []
			state.requests.forEach((r) => {
				if (r._id === payload._id) {
					requests.push(payload)
				} else {
					requests.push(r)
				}
			})
			if (state.currentRequest && payload && state.currentRequest._id === payload._id) {
				state.currentRequest = payload
			}
			state.requests = [...requests]
		}

		validateTasks(state)
	},
	SET_CURRENT_REQUEST(state, payload) {
		state.currentRequest = payload
		if (payload && payload.checkedTasks) state.tasks = payload.checkedTasks
		// // udpate array
		// if (payload) {
		// 	let found = false
		// 	state.requests = state.requests.map(r => {
		// 		if (r._id === payload._id) {
		// 			found = true
		// 			return payload
		// 		} else {
		// 			return r
		// 		}
		// 	})
		// 	if (!found) state.requests.push(payload)
		// }
	},
	UPDATE_CURRENT_REQUEST(state, { configurationPrices }) {
		state.currentRequest.configurationPrices = configurationPrices
	},
	CHECK_TASK(state, { type, variantIdx, finishIdx, configurationIdx }) {
		state.tasks.push({
			type,
			variantIdx,
			finishIdx,
			configurationIdx,
		})
	},
	UNCHECK_TASK(state, { type, variantIdx, finishIdx, configurationIdx }) {
		const idx = state.tasks.findIndex(
			(t) => t.type === type && t.variantIdx === variantIdx && t.finishIdx === finishIdx && t.configurationIdx === configurationIdx
		)
		state.tasks.splice(idx, 1)
	},
	VALIDATE_TASKS(state) {
		validateTasks(state)
	},
	ADD_TIMELINE_ENTRY(state, { text, user, type }) {
		const timeline = [...state.currentRequest.timeline]

		timeline.push({
			createdAt: new Date().toISOString(),
			active: true,
			text,
			type, // event, event+file, internal-note, message, customer-message
			user,
		})

		state.currentRequest.timeline = [...timeline]
	},
	REMOVE_TIMELINE_ENTRY(state, entryId) {
		const timeline = [...state.currentRequest.timeline]
		const idx = timeline.findIndex((e) => e._id === entryId)
		timeline.splice(idx, 1)
		state.currentRequest.timeline = [...timeline]
	},
	SET_FILES(state, files) {
		state.files = files
	},
}

const validateTasks = (state) => {
	state.taskValidation = []

	if (state.currentRequest) {
		// request is valid
		let requestValid = true

		// customer, state is added to each config validation object
		let customerValid = true
		const customerIdx = state.tasks.findIndex((t) => t.type === 'customer')
		if (customerIdx === -1) {
			customerValid = false
			requestValid = false
		}

		// files, positions, textiles
		state.currentRequest.configurations.forEach((config, configIdx) => {
			let motiveValid = true
			// let fileValid = true
			// let positionValid = true
			let textileValid = true

			for (let vKey in config.variants) {
				for (let fKey in config.finishes) {
					const motiveIdx = state.tasks.findIndex(
						(t) => t.type === 'motive' && t.variantIdx === vKey && t.finishIdx === fKey && t.configurationIdx === configIdx
					)
					if (motiveIdx === -1) {
						motiveValid = false
						requestValid = false
					}
					// const fileIdx = state.tasks.findIndex(
					// 	t => t.type === 'file' && t.variantIdx === vKey && t.finishIdx === fKey && t.configurationIdx === configIdx
					// )
					// const positionIdx = state.tasks.findIndex(
					// 	t => t.type === 'position' && t.variantIdx === vKey && t.finishIdx === fKey && t.configurationIdx === configIdx
					// )
					// if (fileIdx === -1) {
					// 	fileValid = false
					// 	requestValid = false
					// }
					// if (positionIdx === -1) {
					// 	positionValid = false
					// 	requestValid = false
					// }
				}
				const textileIdx = state.tasks.findIndex(
					(t) => t.type === 'textile' && t.variantIdx === vKey && t.configurationIdx === configIdx
				)
				if (textileIdx === -1) {
					textileValid = false
					requestValid = false
				}
			}

			state.requestValidation = requestValid
			state.taskValidation.push({
				configIdx,
				motive: motiveValid,
				// file: fileValid,
				// position: positionValid,
				textile: textileValid,
				customer: customerValid,
			})
		})
	} else {
		state.requestValidation = false
		state.taskValidation = []
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
