import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'
import { v4 as uuidv4 } from 'uuid'
import { urlExists } from '@/api/utils.api'

import {
	getByCategory,
	getCategories,
	getCategoriesSS,
	getByCategorySS,
	getBySearchWord,
	getBySearchWordSS,
} from '@/api/externalProducts.api'

const DEFAULT_PRODUCT_IMAGE = 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png'

const uniq = (a) => {
	return a.sort().filter(function (item, pos, ary) {
		return !pos || item != ary[pos - 1]
	})
}

const showError = (error) => {
	Vue.notify({
		group: 'main',
		title: 'Server Error',
		text: error.message,
		type: 'error',
	})
}

var groupBy = (xs, key) => {
	return xs.reduce(function (rv, x) {
		rv[x[key]] = rv[x[key]] || []
		rv[x[key]].push(x)
		return rv
	}, {})
}

const reduceToMainArticle = (array, shopName) => {
	const reduced = []
	const stanleyStellaUrlPrefix = 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames'

	if (shopName === 'stanley-stella') {
		const grouped = groupBy(array, 'StyleCode')

		for (let key in grouped) {
			const item = grouped[key]

			// group colors of product
			const groupedColors = groupBy(item, 'ColorCode')
			const colorVariants = []
			for (let gcKey in groupedColors) {
				const groupedColor = groupedColors[gcKey]

				let sizes = groupedColor.map((colorSize) => {
					return {
						value: colorSize.SizeCode,
						availability: {
							stock: colorSize.Stock,
							published: colorSize.Published,
							productLifecycle: colorSize.ProductLifecycle,
							publishedNewCollection: colorSize.PublishedNewCollection,
							skuStartDate: colorSize.SKU_Start_Date,
						},
					}
				})
				sizes = sizes.filter((thing, index, self) => index === self.findIndex((t) => t.value === thing.value))

				colorVariants.push({
					_id: uuidv4(), // temporary id
					sizes: [...sizes],
					activeSizes: [...sizes],
					color: groupedColor[0].Color,
					value: groupedColor[0].Color,
					hexValue: groupedColor[0].ColorCode,
					articleNumber: groupedColor[0].B2BSKUREF,
					purchasingPrice: 0,
					activeState: false,
					images: {
						front: `${stanleyStellaUrlPrefix}/PFM0_${item[0].StyleCode}_${groupedColor[0].ColorCode}.jpg`,
						back: `${stanleyStellaUrlPrefix}/PBM0_${item[0].StyleCode}_${groupedColor[0].ColorCode}.jpg`,
						side: `${stanleyStellaUrlPrefix}/PSM0_${item[0].StyleCode}_${groupedColor[0].ColorCode}.jpg`,
						detail: `${stanleyStellaUrlPrefix}/SFD0_${item[0].StyleCode}_${groupedColor[0].ColorCode}.jpg`,
						studio: `${stanleyStellaUrlPrefix}/SFM0_${item[0].StyleCode}_${groupedColor[0].ColorCode}.jpg`,
					},
				})
			}

			reduced.push({
				externalArticleNumber: item[0].StyleCode,
				shopName: shopName,
				manufacturer: 'Stanley / Stella',
				name: item[0].StyleName,
				description: item[0].LongDescription,
				grammar: item[0].Weight,
				certificates: (() => {
					let certString = ''

					if (item[0].GOTS === 1) certString += 'Global Organic Textile Standard'
					if (certString.length > 0 && item[0].VEGAN === 1) certString += ' | '
					if (item[0].VEGAN === 1) certString += 'Peta - Approved Vegan'
					if (certString.length > 0 && item[0].GRS === 1) certString += ' | '
					if (item[0].GRS === 1) certString += 'Global Recycled Standard'
					if (certString.length > 0 && item[0].OCS100 === 1) certString += ' | '
					if (item[0].OCS100 === 1) certString += 'Organic 100 content standard'
					if (certString.length > 0 && item[0].OCSBlended === 1) certString += ' | '
					if (item[0].OCSBlended === 1) certString += 'Organic Blended content standard'
					if (certString.length > 0 && item[0].Ecotex === 1) certString += ' | '
					if (item[0].Ecotex === 1) certString += 'OEKO-TEX Standard 100'

					return certString
				})(),
				style: null,
				productType: null,
				materials: item[0].CompositionList,
				label: '',
				category: null,
				sexCategory: null,
				visible: true,
				sizes: item
					.map((i) => {
						return { value: i.SizeCode }
					})
					.filter((thing, index, self) => index === self.findIndex((t) => t.value === thing.value)),
				colors: colorVariants,

				piecesPerBox: item[0].PiecesPerBox,
				productionCountry: item[0].CountryOfOrigin,
				hsCode: item[0].HSCode,
				weight: item[0].WeigthPerUnit,

				mainImage: `${stanleyStellaUrlPrefix}/PFM0_${item[0].StyleCode}_${item[0].ColorCode}.jpg`,
				images: uniq(item.map((i) => i.MainPicture)),
			})
		}
	}

	return reduced
}

const DEFAULT_VARIANT_COLOR_IMAGE = {
	colorCode: '000000',
	front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Creator_C002_front_1626337513672.jpg',
	back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Creator_C002_back_1626337514476.jpg',
	side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
	detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Creator_C002_detail_1626337515846.jpg',
	studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Creator_C002_studio_1626337517129.jpg',
}

const DEFAULT_VARIANT_COLOR = {
	sizes: [
		{ value: 'OS' },
		{ value: 'XXS' },
		{ value: 'XS' },
		{ value: 'S' },
		{ value: 'M' },
		{ value: 'L' },
		{ value: 'XL' },
		{ value: 'XXL' },
		{ value: '3XL' },
	],
	activeSizes: [
		{ value: 'XXS' },
		{ value: 'XS' },
		{ value: 'S' },
		{ value: 'M' },
		{ value: 'L' },
		{ value: 'XL' },
		{ value: 'XXL' },
		{ value: '3XL' },
	],
	color: 'Black',
	value: 'Black',
	hexValue: '000000',
	articleNumber: '123456',
	purchasingPrice: 0,
	activeState: false,
	images: {
		front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Creator_C002_front_1626337513672.jpg',
		back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Creator_C002_back_1626337514476.jpg',
		side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
		detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Creator_C002_detail_1626337515846.jpg',
		studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Creator_C002_studio_1626337517129.jpg',
	},
}

const state = {
	externalProducts: [],
	externalProductCategories: [],
	searchResults: [],
	totalCount: 0,
	searching: false,

	isEditProduct: false, // if true, importProduct, selectedImportColors, selectedImportSizes & selectedImportImages is filled with the data of the already imported product
	isManualProduct: false,
	importProduct: null,
	selectedImportColors: [],
	selectedImportSizes: [],
	selectedImportImages: [],

	selectedVariant: null,
}

const getters = {
	getField,
	getExternalProducts: (state) => {
		return state.externalProducts
	},
	getCategories: (state) => {
		return state.externalProductCategories
	},
	getTotalCount: (state) => {
		return state.totalCount
	},
	getSearchResults: (state) => {
		return state.searchResults
	},
	getSearchState: (state) => {
		return state.searching
	},
	getImportProduct: (state) => {
		return state.importProduct
	},
	getSelectedImportColors: (state) => {
		return state.selectedImportColors
	},
	getSelectedImportSizes: (state) => {
		return state.selectedImportSizes
	},
	getSelectedImportImages: (state) => {
		return state.selectedImportImages
	},
	getImportProductValue: (state) => (key) => {
		return state.importProduct[key]
	},
	getSelectedVariant: (state) => {
		if (state.importProduct && state.importProduct.colors) {
			const variant = state.importProduct.colors.find((c) => c._id === state.selectedVariant)
			return variant
		} else {
			return false
		}
	},
}

const actions = {
	fetchCategories: async function ({ commit }) {
		try {
			const response = await getCategories()
			commit('SET_EXTERNAL_PRODUCT_CATEGORIES', response.data)
		} catch (error) {
			showError(error)
		}
	},
	fetchCategoriesSS: async function ({ commit }) {
		try {
			const response = await getCategoriesSS()
			const categoriesDup = response.map((r) => r.Category)
			const categories = uniq(categoriesDup)
			const transformedResponse = categories.map((c) => {
				const subsObjs = response.filter((r) => r.Category === c)
				console.log(subsObjs)
				const subs = subsObjs.map((so) => so.Code)
				return {
					_id: c,
					category: c,
					subcategories: subs,
				}
			})
			commit('SET_EXTERNAL_PRODUCT_CATEGORIES', transformedResponse)
		} catch (error) {
			showError(error)
		}
	},
	fetchProducts: async function ({ commit }, data) {
		try {
			const response = await getByCategory(data)
			commit('SET_EXTERNAL_PRODUCTS', response.data)
		} catch (error) {
			showError(error)
		}
	},
	searchProduct: async function ({ commit }, data) {
		console.log('searchProduct')
		try {
			commit('SET_SEARCHING_STATE', true)

			console.log(data)

			// L-Shop
			// const lshop = await getBySearchWord(data)
			// const transformedLShop = lshop.data.products.map((p) => {
			// 	p.category = null
			// 	p.style = null
			// 	p.productType = null
			// 	return p
			// })

			// Stanley/Stella
			const stanleyCode = await getBySearchWordSS(data, true) // search by StyleCode
			const stanleyName = await getBySearchWordSS(data) // search by StyleName
			const transformedStanley = reduceToMainArticle(stanleyCode.concat(stanleyName), 'stanley-stella')

			// combine results
			// const products = transformedStanley.concat(transformedLShop)

			const products = transformedStanley

			commit('SET_SEARCHING_STATE', false)
			commit('SET_SEARCH_RESULTS', { products })

			return { notEmpty: products.length > 0 }
		} catch (error) {
			console.error(error)
			commit('SET_SEARCHING_STATE', false)
			showError(error)
		}
	},
	searchProductForEdit: async function ({ commit, dispatch }, data) {
		console.log('searchProductForEdit')
		return new Promise(async (resolve, reject) => {
			try {
				commit('SET_SEARCHING_STATE', true)
				commit('SET_IS_MANUAL_PRODUCT', false)

				let products

				// L-Shop
				// if (data.shopName === 'l-shop') {
				// 	const lshop = await getBySearchWord(data.externalArticleNumber)
				// 	products = lshop.data.products.map((p) => {
				// 		p.category = null
				// 		p.style = null
				// 		p.productType = null
				// 		return p
				// 	})
				// }

				// Stanley/Stella
				if (data.shopName === 'stanley-stella') {
					const stanleyCode = await getBySearchWordSS(data.externalArticleNumber, true) // search by StyleCode
					const stanleyName = await getBySearchWordSS(data.externalArticleNumber) // search by StyleName
					products = reduceToMainArticle(stanleyCode.concat(stanleyName), 'stanley-stella')
				}

				// Custom
				if (data.shopName === 'custom') {
					console.log(data)
					products = [data.product]
				}

				// Manual
				if (data.product.isManualProduct) {
					console.log(data)
					products = [data.product]
					commit('SET_IS_MANUAL_PRODUCT', true)
				}

				let product

				if (!products) {
					resolve({ error: 'No products found.' })
					return
				}

				if (products.length > 1) {
					for (let item of products) {
						if (item.externalArticleNumber === data.externalArticleNumber) product = item
					}
					if (!product) product = products
				} else if (products.length === 1) {
					product = products[0]
				}

				commit('SET_SEARCHING_STATE', false)

				if (!product) {
					// return { error: 'No product found.' }
					resolve({ error: 'No product found.' })
				} else if (product.length > 1) {
					// return { error: 'More than one product found.' }
					resolve({ error: 'More than one product found.' })
				} else {
					commit('SET_IS_EDIT_PRODUCT', true)

					// set selected values from imported product
					commit('SET_IMPORT_COLORS', data.product.colors)
					commit('SET_IMPORT_SIZES', data.product.sizes)

					product.colors.forEach((c) => {
						const exists = data.product.colors.find((color) => color.hexValue === c.hexValue)
						if (!exists) data.product.colors.push(c)
					})

					commit('SET_IMPORT_PRODUCT', data.product)

					// get all images of product.colors.images
					let images = []
					data.product.colors.forEach((c) => {
						if (c.images) images.push({ ...c.images, variantId: c._id })
					})

					console.log('ADDING IMPORT IMAGES')
					console.log(images)

					commit('SET_IMPORT_IMAGES', images)

					// return { success: true }
					resolve({ success: true })
				}
			} catch (error) {
				console.error(error)
				showError(error)
				reject(error)
			}
		})
	},
	resetSearchResults({ commit }) {
		commit('SET_SEARCH_RESULTS', { products: [] })
	},
	fetchProductsSS: async function ({ commit }, data) {
		try {
			const response = await getByCategorySS(data)
			console.log(response)
			const transformedResponse = response.map((r) => {
				return {
					_id: r.B2BSKUREF,
					image: r.MainPicture,
					name: r.StyleName,
					sizes: [r.SizeCode],
					colors: [r.Color],
					count: '',
					category: r.Category,
					subcategory: '',
				}
			})
			commit('SET_EXTERNAL_PRODUCTS', {
				products: transformedResponse.splice(0, 20),
				totalCount: transformedResponse.length,
			})
		} catch (error) {
			showError(error)
		}
	},
	extendProducts: async function ({ commit }, data) {
		try {
			const response = await getByCategory(data)
			commit('EXTEND_EXTERNAL_PRODUCTS', response.data)
		} catch (error) {
			showError(error)
		}
	},
	resetProducts({ commit }) {
		commit('SET_EXTERNAL_PRODUCTS', { products: [], totalCount: 0 })
		commit('SET_EXTERNAL_PRODUCT_CATEGORIES', [])
	},
	setImportProduct({ commit }, product) {
		commit('SET_IS_MANUAL_PRODUCT', false)
		commit('SET_IMPORT_PRODUCT', product)
	},
	setImportColors({ commit }, colors) {
		commit('SET_IMPORT_COLORS', colors)
	},
	setImportSizes({ commit }, sizes) {
		commit('SET_IMPORT_SIZES', sizes)
	},
	setImportImages({ commit }, colors) {
		let images = []
		colors.forEach((c) => {
			if (c.images) images.push({ ...c.images, variantId: c._id })
		})
		commit('SET_IMPORT_IMAGES', images)
	},
	updateImportImage({ commit }, data) {
		commit('UPDATE_IMPORT_IMAGES', data)
	},
	updateImportProduct({ commit }, { key, value }) {
		commit('UPDATE_IMPORT_PRODUCT', { key, value })
	},
	updateImportColorVariant({ commit }, { color, key, value }) {
		commit('UPDATE_IMPORT_COLOR_VARIANT', { color, key, value })
	},
	updateImportColorVariantV2({ commit }, { variantId, key, value }) {
		commit('UPDATE_IMPORT_COLOR_VARIANT_V2', { variantId, key, value })
	},
	urlExists({ state }, url) {
		if (!state) console.log('')
		return urlExists(url).then((res) => {
			return res.exists
		})
	},
	resetImportData({ commit }) {
		commit('RESET_IMPORT_DATA')
	},
	setSelectedVariant({ commit }, variantId) {
		commit('SET_SELECTED_VARIANT', variantId)
	},
	createProductManually({ commit }) {
		const variantId = uuidv4()
		const product = {
			_id: 'MANUAL',
			isManualProduct: true,
			externalArticleNumber: '123456',
			shopName: 'manual',
			name: '',
			description: '',
			grammar: '180',
			certificates: '',
			style: 'standard',
			productType: 'shirt',
			materials: '',
			label: '',
			category: 'shirt',
			visible: true,
			colors: [
				{
					_id: variantId,
					...DEFAULT_VARIANT_COLOR,
				},
			],
			piecesPerBox: 100,
			productionCountry: '',
			hsCode: '',
			weight: 0,
			mainImage: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Creator_C002_front_1626337513672.jpg',
			updates: [],
			manufacturer: 'manual',
			sexCategory: 'unisex',
		}
		commit('SET_IMPORT_PRODUCT', product)
		commit('SET_IMPORT_SIZES', null)
		commit('SET_IMPORT_COLORS', product.colors)
		commit('SET_IMPORT_IMAGES', [{ ...product.colors[0].images, variantId }])
		commit('SET_IS_MANUAL_PRODUCT', true)
	},
	addVariant({ commit }) {
		commit('ADD_VARIANT')
	},
	deleteVariant({ commit }, hexValue) {
		commit('DELETE_VARIANT', hexValue)
	},
	updateColorImages({ commit }, data) {
		commit('UPDATE_COLOR_IMAGES', data)
	},
}

const mutations = {
	updateField,
	SET_EXTERNAL_PRODUCTS: function (state, payload) {
		state.externalProducts = payload.products
		state.totalCount = payload.totalCount
	},
	EXTEND_EXTERNAL_PRODUCTS: function (state, payload) {
		state.externalProducts = state.externalProducts.concat(payload.products)
		state.totalCount = payload.totalCount
	},
	SET_EXTERNAL_PRODUCT_CATEGORIES(state, categories) {
		state.externalProductCategories = categories
	},
	SET_SEARCH_RESULTS(state, payload) {
		state.searching = false
		state.searchResults = payload.products
		state.importProduct = null
	},
	SET_SEARCHING_STATE(state, payload) {
		state.searching = payload
	},
	SET_IMPORT_PRODUCT(state, product) {
		state.importProduct = product
	},
	SET_IMPORT_COLORS(state, colors) {
		state.selectedImportColors = colors
	},
	SET_IMPORT_SIZES(state, sizes) {
		state.selectedImportSizes = sizes
	},
	SET_IMPORT_IMAGES(state, images) {
		state.selectedImportImages = images
	},
	UPDATE_IMPORT_IMAGES(state, data) {
		// for images for all colors
		const images = [...state.selectedImportImages]
		// const iIdx = images.findIndex((i) => i.colorCode === data.hexValue)
		const iIdx = images.findIndex((i) => i.variantId === data.variantId)
		if (iIdx > -1) images[iIdx][data.type] = data.imageUrl
		state.selectedImportImages = [...images]

		// also update importProduct
		const importProduct = { ...state.importProduct }
		const cIdx = importProduct.colors.findIndex((c) => c._id === data.variantId)
		if (cIdx > -1) importProduct.colors[cIdx].images[data.type] = data.imageUrl
		state.importProduct = { ...importProduct }
	},
	UPDATE_IMPORT_PRODUCT(state, { key, value }) {
		const tmp = { ...state.importProduct }
		tmp[key] = value
		state.importProduct = tmp
	},
	UPDATE_IMPORT_COLOR_VARIANT(state, { color, key, value }) {
		const importProduct = { ...state.importProduct }
		// const idx = importProduct.colorVariants.findIndex(cv => cv.color === color)
		const idx = importProduct.colors.findIndex((cv) => cv.color === color)
		console.log(idx)
		// importProduct.colorVariants[idx][key] = value
		importProduct.colors[idx][key] = value
		// console.log(importProduct.colorVariants[idx][key])
		console.log(importProduct.colors[idx][key])
		state.importProduct = { ...importProduct }
	},
	UPDATE_IMPORT_COLOR_VARIANT_V2(state, { variantId, key, value }) {
		const importProduct = { ...state.importProduct }
		const idx = importProduct.colors.findIndex((cv) => cv._id === variantId)

		importProduct.colors[idx][key] = value

		state.importProduct = { ...importProduct }
	},
	SET_IS_EDIT_PRODUCT(state, value) {
		state.isEditProduct = value
	},
	RESET_IMPORT_DATA(state) {
		// reset all data
		state.externalProducts = []
		state.externalProductCategories = []
		state.searchResults = []
		state.totalCount = 0
		state.searching = false
		state.isEditProduct = false
		state.importProduct = null
		state.selectedImportColors = []
		state.selectedImportSizes = []
		state.selectedImportImages = []
	},
	SET_SELECTED_VARIANT: function (state, variantId) {
		state.selectedVariant = variantId
	},
	SET_IS_MANUAL_PRODUCT: function (state, value) {
		state.isManualProduct = value
	},
	ADD_VARIANT: function (state) {
		const variantId = uuidv4()
		state.importProduct.colors.push({
			_id: variantId,
			...DEFAULT_VARIANT_COLOR,
		})

		// SET_IMPORT_COLORS
		state.selectedImportColors = state.importProduct.colors

		// get all images from state.importProduct.colors in array
		const images = state.importProduct.colors.map((c) => ({ ...c.images, variantId: c._id }))

		// SET_IMPORT_IMAGES
		state.selectedImportImages = images
	},
	DELETE_VARIANT: function (state, variantId) {
		const importProduct = { ...state.importProduct }
		const idx = importProduct.colors.findIndex((cv) => cv._id === variantId)
		importProduct.colors.splice(idx, 1)
		importProduct.colorImages.splice(idx, 1)
		state.importProduct = { ...importProduct }

		// SET_IMPORT_COLORS
		state.selectedImportColors = state.importProduct.colors
		// SET_IMPORT_IMAGES
		state.selectedImportImages = state.importProduct.colorImages
	},
	UPDATE_COLOR_IMAGES: function (state, data) {
		const selectedImportImages = [...state.selectedImportImages]
		const idx = selectedImportImages.findIndex((i) => i.colorCode === data.oldHexValue)
		if (selectedImportImages[idx]) {
			selectedImportImages[idx].colorCode = data.newHexValue
		} else {
			const newImg = { ...DEFAULT_VARIANT_COLOR_IMAGE }
			newImg.colorCode = data.newHexValue
			selectedImportImages.push(newImg)
		}
		state.selectedImportImages = [...selectedImportImages]
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
