export default [
	// Anfragen
	{
		path: '/wercbrain/requests',
		name: 'wercbrain-requests',
		component: () => import('@/views/wercbrain/requests/Requests.vue'),
		meta: {
			resource: 'Internal',
			action: 'read',
			pageTitle: 'Anfragen',
			breadcrumb: [
				{
					text: 'Anfragen',
					to: { name: 'wercbrain-requests' },
					active: true,
				},
			],
		},
	},
	{
		path: '/wercbrain/requests/:requestId',
		name: 'wercbrain-request-details',
		component: () => import('@/views/wercbrain/requests/RequestDetails.vue'),
		meta: {
			resource: 'Internal',
			action: 'read',
		},
		// meta: {
		// 	pageTitle: 'Anfrage',
		// 	breadcrumb: [
		// 		{
		// 			text: 'Anfragen',
		// 			to: { name: 'wercbrain-requests' },
		// 		},
		// 		{
		// 			text: 'Anfrage Details',
		// 			active: true,
		// 		},
		// 	],
		// },
	},
	// Projekte
	{
		path: '/wercbrain/projects',
		name: 'wercbrain-projects',
		component: () => import('@/views/wercbrain/projects/Projects.vue'),
		meta: {
			resource: 'Internal',
			action: 'read',
			// pageTitle: 'Projekte',
			// breadcrumb: [
			// 	{
			// 		text: 'Projekte',
			// 		to: { name: 'wercbrain-projects' },
			// 		active: true,
			// 	},
			// ],
			contentRenderer: 'sidebar-left',
			contentClass: 'chat-application',
		},
	},
	{
		path: '/wercbrain/projects/:requestId',
		name: 'wercbrain-project-details',
		component: () => import('@/views/wercbrain/projects/ProjectDetails.vue'),
		meta: {
			resource: 'Internal',
			action: 'read',
			pageTitle: 'Projekt',
			breadcrumb: [
				{
					text: 'Projekte',
					to: { name: 'wercbrain-projects' },
				},
				{
					text: 'Projekt Details',
					active: true,
				},
			],
		},
	},
	// Produktionsplan
	{
		path: '/wercbrain/production',
		name: 'wercbrain-production',
		component: () => import('@/views/wercbrain/production/Production.vue'),
		meta: {
			resource: 'Internal',
			action: 'read',
			pageTitle: 'Produktionsplan',
			breadcrumb: [
				{
					text: 'Produktionsplan',
					to: { name: 'wercbrain-production' },
					active: true,
				},
			],
		},
	},
	// Ext. Bestellungen
	{
		path: '/wercbrain/external-orders',
		name: 'wercbrain-external-orders',
		component: () => import('@/views/wercbrain/externalOrders/ExternalOrders.vue'),
		meta: {
			resource: 'Internal',
			action: 'read',
			pageTitle: 'Ext. Bestellungen',
			breadcrumb: [
				{
					text: 'Ext. Bestellungen',
					to: { name: 'wercbrain-external-orders' },
					active: true,
				},
			],
		},
	},
	// Produkte
	{
		path: '/wercbrain/settings/products',
		name: 'wercbrain-products',
		component: () => import('@/views/wercbrain/products/Products.vue'),
		meta: {
			resource: 'Internal',
			action: 'read',
			pageTitle: 'Produkte',
			breadcrumb: [
				{
					text: 'Produkte',
					active: true,
				},
			],
		},
	},
	// File-Explorer
	{
		path: '/wercbrain/file-explorer',
		name: 'wercbrain-file-explorer',
		component: () => import('@/views/wercbrain/fileExplorer/FileExplorer.vue'),
		meta: {
			resource: 'Internal',
			action: 'read',
			pageTitle: 'Dateien',
			contentClass: 'ecommerce-application',
			contentRenderer: 'sidebar-left-detached',
			breadcrumb: [
				{
					text: 'Dateien',
					to: { name: 'wercbrain-file-explorer' },
					active: true,
				},
			],
		},
	},
	// Kunden
	{
		path: '/wercbrain/customers',
		name: 'wercbrain-customers',
		component: () => import('@/views/wercbrain/customers/Customers.vue'),
		meta: {
			resource: 'Internal',
			action: 'read',
			pageTitle: 'Kunden',
			breadcrumb: [
				{
					text: 'Kunden',
					to: { name: 'wercbrain-customers' },
					active: true,
				},
			],
		},
	},
	{
		path: '/wercbrain/customers/details/:id',
		name: 'wercbrain-customers-details',
		component: () => import('@/views/wercbrain/customers/CustomerDetails.vue'),
		meta: {
			resource: 'Internal',
			action: 'read',
			pageTitle: 'Kunden',
			breadcrumb: [
				{
					text: 'Kunden',
					to: { name: 'wercbrain-customers' },
				},
				{
					text: 'Kunden Details',
					active: true,
				},
			],
		},
	},
	{
		path: '/wercbrain/customers/edit/:id',
		name: 'wercbrain-customers-edit',
		component: () => import('@/views/wercbrain/customers/CustomerEdit.vue'),
		meta: {
			resource: 'Internal',
			action: 'read',
			pageTitle: 'Kunden',
			breadcrumb: [
				{
					text: 'Kunden',
					to: { name: 'wercbrain-customers' },
				},
				{
					text: 'Kunden Bearbeiten',
					active: true,
				},
			],
		},
	},
	// Ressourcen
	{
		path: '/wercbrain/resources',
		name: 'wercbrain-resources',
		component: () => import('@/views/wercbrain/resources/Resources.vue'),
		meta: {
			resource: 'Internal',
			action: 'read',
			pageTitle: 'Ressourcen',
			breadcrumb: [
				{
					text: 'Ressourcen',
					to: { name: 'wercbrain-resources' },
					active: true,
				},
			],
		},
	},
	// Mitarbeiter
	{
		path: '/wercbrain/employees',
		name: 'wercbrain-employees',
		component: () => import('@/views/wercbrain/employees/Employees.vue'),
		meta: {
			resource: 'Internal',
			action: 'read',
			pageTitle: 'Mitarbeiter',
			breadcrumb: [
				{
					text: 'Mitarbeiter',
					to: { name: 'wercbrain-employees' },
					active: true,
				},
			],
		},
	},
	{
		path: '/wercbrain/employees/details/:id',
		name: 'wercbrain-employees-details',
		component: () => import('@/views/wercbrain/employees/EmployeeDetails.vue'),
		meta: {
			resource: 'Internal',
			action: 'read',
			pageTitle: 'Mitarbeiter',
			breadcrumb: [
				{
					text: 'Mitarbeiter',
					to: { name: 'wercbrain-employees' },
				},
				{
					text: 'Mitarbeiter Details',
					active: true,
				},
			],
		},
	},
	{
		path: '/wercbrain/employees/edit/:id',
		name: 'wercbrain-employees-edit',
		component: () => import('@/views/wercbrain/employees/EmployeeEdit.vue'),
		meta: {
			resource: 'Internal',
			action: 'read',
			pageTitle: 'Mitarbeiter',
			breadcrumb: [
				{
					text: 'Mitarbeiter',
					to: { name: 'wercbrain-employees' },
				},
				{
					text: 'Mitarbeiter Bearbeiten',
					active: true,
				},
			],
		},
	},
	// Kalender
	{
		path: '/wercbrain/calendar',
		name: 'wercbrain-calendar',
		component: () => import('@/views/wercbrain/calendar/Calendar.vue'),
		meta: {
			resource: 'Internal',
			action: 'read',
			pageTitle: 'Kalender',
			breadcrumb: [
				{
					text: 'Kalender',
					to: { name: 'wercbrain-calendar' },
					active: true,
				},
			],
		},
	},
	// Allgemeine Einstellungen
	{
		path: '/wercbrain/settings/general',
		name: 'wercbrain-settings-general',
		component: () => import('@/views/wercbrain/settings/general/General.vue'),
		meta: {
			resource: 'Internal',
			action: 'read',
			pageTitle: 'Allgemeine Einstellungen',
			breadcrumb: [
				// {
				// 	text: 'Einstellungen',
				// 	active: true,
				// },
				{
					text: 'Allgemeine Einstellungen',
					active: true,
				},
			],
		},
	},
	// Wörterbuch
	{
		path: '/wercbrain/settings/dictionary',
		name: 'wercbrain-settings-dictionary',
		component: () => import('@/views/wercbrain/settings/dictionary/Dictionary.vue'),
		meta: {
			resource: 'Internal',
			action: 'read',
			pageTitle: 'Wörterbuch',
			breadcrumb: [
				// {
				// 	text: 'Einstellungen',
				// 	active: true,
				// },
				{
					text: 'Wörterbuch',
					active: true,
				},
			],
		},
	},
	// Calc Settings
	{
		path: '/wercbrain/settings/calc-variables',
		name: 'wercbrain-settings-calc-variables',
		component: () => import('@/views/wercbrain/settings/calcVariables/CalcVariables.vue'),
		meta: {
			resource: 'Internal',
			action: 'read',
			pageTitle: 'Angebots-Variablen',
			breadcrumb: [
				// {
				// 	text: 'Einstellungen',
				// 	active: true,
				// },
				{
					text: 'Angebots-Variablen',
					active: true,
				},
			],
		},
	},
]
