import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'
import { getAll } from '@/api/calcVariables.api'

const state = {
	variables: {},
}

const getters = {
	getField,
}

const actions = {
	fetchVariables: async function ({ commit }) {
		try {
			const response = await getAll()
			commit('SET_VARIABLES', response.calcVariables)
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
}

const mutations = {
	updateField,
	SET_VARIABLES: function (state, payload) {
		state.variables = payload
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
