import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'
import { getOneTimeToken } from '@/api/auth.api'

const state = {}

const getters = {
	getField,
}

const actions = {
	getOneTimeToken: async function ({ commit }) {
		try {
			const promise = getOneTimeToken()

			return promise
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
}

const mutations = {
	updateField,
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
