import httpClient from './httpClient'

const END_POINT = process.env.VUE_APP_BASE_URL + '/requests'
const PAYMENT_END_POINT = process.env.VUE_APP_BASE_URL + '/payment'

const getAll = () => {
	return httpClient.get(END_POINT)
}

const getById = orderId => {
	return httpClient.get(END_POINT + '/' + orderId)
}

const getByCustomer = customerId => {
	return httpClient.get(END_POINT + '/customer/' + customerId)
}

const createTest = () => {
	return httpClient.get(END_POINT + '/create-test')
}

const create = data => {
	return httpClient.post(END_POINT + '/create', { data })
}

const dummyCreate = data => {
	return httpClient.post(END_POINT + '/dummy-create', { data })
}

// const deleteById = (orderId) => {
// 	return httpClient.delete(END_POINT + 'delete', { orderId })
// }

const update = (orderId, data) => {
	return httpClient.post(END_POINT + '/' + orderId + '/update', { data })
}

const assignUser = (orderId, employee) => {
	return httpClient.post(END_POINT + '/' + orderId + '/assign-user', { employee })
}

const addNote = (orderId, data) => {
	return httpClient.post(END_POINT + '/' + orderId + '/add-note', { data })
}

const deleteEntry = (orderId, data) => {
	return httpClient.post(END_POINT + '/' + orderId + '/delete-timeline-entry', { data })
}

const updatePrices = (orderId, data) => {
	return httpClient.post(END_POINT + '/' + orderId + '/update/prices', { data })
}

const addMessage = (requrestId, data) => {
	return httpClient.post(END_POINT + '/' + requrestId + '/add-admin-message', { data })
}

const calculateCosts = requrestId => {
	return httpClient.get(END_POINT + '/' + requrestId + '/calc-costs')
}

const calculateVariantCosts = (requrestId, data) => {
	return httpClient.post(END_POINT + '/' + requrestId + '/variant/calc-costs', { data })
}

const updateCosts = (requrestId, data) => {
	return httpClient.post(END_POINT + '/' + requrestId + '/update-costs', { data })
}

const createOffer = requrestId => {
	return httpClient.get(END_POINT + '/' + requrestId + '/create-offer')
}

const resetValues = requrestId => {
	return httpClient.get(END_POINT + '/' + requrestId + '/reset-calc-values')
}

const setBankTransferPayment = requestId => {
	return httpClient.get(`${PAYMENT_END_POINT}/${requestId}/bank-transfer/set-payment`)
}

const addFile = (requrestId, fileId) => {
	return httpClient.post(END_POINT + '/' + requrestId + '/add-file', { fileId })
}

export {
	getAll,
	getById,
	getByCustomer,
	update,
	create,
	dummyCreate,
	createTest,
	addNote,
	deleteEntry,
	updatePrices,
	addMessage,
	calculateCosts,
	calculateVariantCosts,
	updateCosts,
	createOffer,
	resetValues,
	assignUser,
	setBankTransferPayment,
	addFile,
}
