// @TODO: read up on API factories!!
// Test-API: https://httpbin.org/#/HTTP_Methods/get_get

import Vue from 'vue'
import axios from 'axios'
import { logout } from '@/auth/utils'
import jwtConfig from '../@core/auth/jwt/jwtDefaultConfig'

// import { throttleAdapterEnhancer } from 'axios-extensions'

// to use throttling we need to setup client caching first: https://medium.com/locale-ai/architecting-http-clients-in-vue-js-applications-for-efficient-network-communication-991cf1df1cb2
// @TODO: Do we need this?
// const throttleConfig = {
//     threshold: 2*1000 // 2 seconds
// }

const httpClient = axios.create({
	baseUrl: process.env.VUE_APP_BASE_URL,
	timeout: 120000, // indicates, 1000ms ie. 1 second
	// adapter: throttleAdapterEnhancer(axios.defaults.adapter, throttleConfig),
	headers: {
		'Content-Type': 'application/json',
		// anything you want to add to the headers
	},
})

// AUTHENTICATION

const getAuthToken = () => {
	return localStorage.getItem('accessToken')
}

const authInterceptor = config => {
	config.headers['Authorization'] = `${jwtConfig.tokenType} ${getAuthToken()}`
	return config
}

httpClient.interceptors.request.use(authInterceptor)

// ERRORS

// interceptor to catch errors
const errorInterceptor = error => {
	const { config, response } = error
	const originalRequest = config

	// all the error responses
	switch (error.response.status) {
		case 400:
			console.error(error.response.status, error.message)
			Vue.notify({
				group: 'main',
				title: 'Nothing to display',
				text: 'Data Not Found',
				type: 'error',
			})
			break

		case 401: // authentication error, logout the user
			if (!isAlreadyFetchingAccessToken) {
				isAlreadyFetchingAccessToken = true
				refreshToken()
					.then(res => {
						// Update accessToken in localStorage
						setToken(res.accessToken)
						setRefreshToken(res.refreshToken)

						onAccessTokenFetched(res.accessToken)
					})
					.catch(() => {
						logout()
					})
					.finally(() => {
						isAlreadyFetchingAccessToken = false
					})
			}
			const retryOriginalRequest = new Promise(resolve => {
				addSubscriber(accessToken => {
					originalRequest.headers.Authorization = `${jwtConfig.tokenType} ${accessToken}`
					resolve(httpClient(originalRequest))
				})
			})
			return retryOriginalRequest

		default:
			console.error(error.response.status, error.response.data.message)
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
	}
	const errMsg = error.response.data.message
	return Promise.reject(errMsg)
}

let isAlreadyFetchingAccessToken = false
let subscribers = []

// Interceptor for responses
const responseInterceptor = response => {
	switch (response.status) {
		case 200:
			// yay!
			break
		// any other cases
		default:
		// default case
	}
	return response.data
}

const onAccessTokenFetched = accessToken => {
	subscribers = subscribers.filter(callback => callback(accessToken))
}

const addSubscriber = callback => {
	subscribers.push(callback)
}

const setToken = value => {
	localStorage.setItem(jwtConfig.storageTokenKeyName, value)
}

const setRefreshToken = value => {
	localStorage.setItem(jwtConfig.storageRefreshTokenKeyName, value)
}

const getRefreshToken = () => {
	return localStorage.getItem(jwtConfig.storageRefreshTokenKeyName)
}

const refreshToken = () => {
	return httpClient.post(process.env.VUE_APP_BASE_URL + jwtConfig.refreshEndpoint, {
		refreshToken: getRefreshToken(),
	})
}

httpClient.interceptors.response.use(responseInterceptor, errorInterceptor)

export default httpClient
