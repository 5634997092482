import { getField, updateField } from 'vuex-map-fields'
import { getAll, getById, getByModule, create, update, deleteById } from '@/api/dictionary.api'

const state = {
	dictionary: [], // admin data
	readableDictionary: {}, // frontend data
}

const getters = {
	getField,
	getEntry: state => key => {
		return state.dictionary[key]
	},
}

const actions = {
	fetchReadableAll: async function({ commit }) {
		try {
			const result = await getAll()
			commit('SET_READABLE_DICTIONARY', result.data)
		} catch (e) {
			console.error(e)
		}
	},
	fetchReadableByModule: async function({ commit }, module) {
		try {
			const result = await getByModule(module)
			commit('SET_READABLE_DICTIONARY', result.data)
		} catch (e) {
			console.error(e)
		}
	},
	fetchAll: async function({ commit }) {
		try {
			const result = await getAll()
			commit('SET_DICTIONARY_ENTRIES', result.data)
			return result
		} catch (e) {
			console.error(e)
		}
	},
	fetchById: async function({ commit }, id) {
		try {
			const result = await getById(id)
			commit('SET_DICTIONARY_ENTRY', result.data)
		} catch (e) {
			console.error(e)
		}
	},
	fetchByModule: async function({ commit }, module) {
		try {
			const result = await getByModule(module)
			commit('SET_DICTIONARY_ENTRIES', result.data)
		} catch (e) {
			console.error(e)
		}
	},
	createEntry: async function({ commit }, data) {
		try {
			const result = await create(data)
			commit('SET_DICTIONARY_ENTRY', result.created)
		} catch (e) {
			console.error(e)
		}
	},
	updateEntry: async function({ commit }, data) {
		try {
			const result = await update(data._id, { value: data.value, variables: data.variables })
			commit('SET_DICTIONARY_ENTRY', result.updated)
		} catch (e) {
			console.error(e)
		}
	},
	deleteEntry: async function({ commit }, id) {
		try {
			const result = await deleteById(id)
			commit('DELETE_DICTIONARY_ENTRY', result.deletedId)
		} catch (e) {
			console.error(e)
		}
	},
}

const mutations = {
	updateField,
	SET_READABLE_DICTIONARY: function(state, entries) {
		const dict = { ...state.readableDictionary }
		entries.forEach(entry => {
			dict[`${entry.module}_${entry.part}_${entry.key}`] = entry.value
		})
		state.readableDictionary = { ...dict }
	},
	SET_DICTIONARY_ENTRIES: function(state, entries) {
		state.dictionary = [...entries]
	},
	SET_DICTIONARY_ENTRY: function(state, entry) {
		let found = false
		state.dictionary.forEach(doc => {
			if (doc._id === entry._id) {
				doc = entry
				found = true
			}
		})
		if (!found) state.dictionary.push(entry)
	},
	DELETE_DICTIONARY_ENTRY: function(state, deletedId) {
		state.dictionary.forEach((doc, idx) => {
			if (doc._id === deletedId) state.dictionary.splice(idx, 1)
		})
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
