import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'
import { getAllByCustomer, newAddress, deleteAddress, updateAddress } from '@/api/customerAddresses.api'

const state = {
	addresses: [],
}

const getters = {
	getField,
	getAddresses: state => {
		return state.addresses
	},
}

const actions = {
	// { q, perPage, page, sortBy, sortDesc, role, plan, status }
	fetchAddresses: async function({ commit }, customerId) {
		try {
			const response = await getAllByCustomer(customerId)
			commit('SET_ADDRESSES', response)
			return {
				data: {
					addresses: response.addresses,
					total: response.addressesCount,
				},
			}
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	updateAddress: async function({ commit, dispatch }, data) {
		console.log(data)
		try {
			const resp = await updateAddress(data)
			return resp
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	newAddress: async function({ commit, dispatch }, data) {
		try {
			const resp = await newAddress(data)
			return resp
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	deleteAddress: async function({ commit, dispatch, rootStore }, id) {
		try {
			const resp = await deleteAddress(id)
			return resp
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
    
}

const mutations = {
	// updateField,
	SET_ADDRESSES: function(state, payload) {
		state.addresses = payload
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
