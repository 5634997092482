import httpClient from './httpClient'

const END_POINT = process.env.VUE_APP_BASE_URL + '/internal-products'

const getAll = () => {
	return httpClient.get(END_POINT)
}

const getById = id => {
	return httpClient.get(END_POINT + '/' + id)
}

const getByCategory = category => {
	return httpClient.get(END_POINT + '/category/' + category)
}

const create = data => {
	return httpClient.post(END_POINT + '/create', { data })
}

const update = (productId, data) => {
	console.log(productId)
	console.log(data)
	return httpClient.post(END_POINT + '/' + productId + '/update', { data })
}

const deleteById = productId => {
	return httpClient.get(END_POINT + '/' + productId + '/delete')
}

const orderProducts = (orderedProducts, category) => {
	return httpClient.post(END_POINT + '/order-products', { orderedProducts, category })
}

export { getAll, getById, getByCategory, create, update, deleteById, orderProducts }
