import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'

// import { getAll, getById, getByCategory, create, update } from '@/api/internalProducts.api'
import {
	getAll as getAllProducts,
	create as createProduct,
	deleteById as deleteProduct,
	update as updateProduct,
	orderProducts as orderProductsApi,
} from '@/api/internalProducts.api'
import { getAll as getAllCategories } from '@/api/productCategories.api'

// const uniq = (a) => {
// 	return a.sort().filter(function (item, pos, ary) {
// 		return !pos || item != ary[pos - 1]
// 	})
// }

const showError = (error) => {
	Vue.notify({
		group: 'main',
		title: 'Server Error',
		text: error.message,
		type: 'error',
	})
}

const state = {
	internalProducts: [],
	categories: [],
	totalCount: 0,
	editProduct: {},
	editProductRef: {},
	selectedVariant: null,
}

const getters = {
	getField,
	getProducts: (state) => {
		return state.internalProducts
	},
	getProductsByCategory:
		(state) =>
		(category, limit = 1000, offset = 0) => {
			return state.internalProducts.filter((p) => p.category === category).splice(offset, limit)
		},
	getCategories: (state) => {
		return state.categories
	},
	getTotalCount: (state) => {
		return state.totalCount
	},
	isAvailabled: (state) => (externalArticleNumber) => {
		return !!state.internalProducts.find((ip) => ip.externalArticleNumber === externalArticleNumber)
	},
	getProductById: (state) => (internalProductId) => {
		return state.internalProducts.find((ip) => ip._id === internalProductId)
	},
	getSelectedVariant: (state) => {
		console.log(state.editProduct)
		if (state.editProduct && state.editProduct.colors) {
			const variant = state.editProduct.colors.find((c) => c.hexValue === state.selectedVariant)
			return variant
		} else {
			return false
		}
	},
}

const actions = {
	fetchProducts: async function ({ commit }) {
		try {
			const products = await getAllProducts()
			const categories = await getAllCategories()
			commit('SET_INTERNAL_PRODUCTS', {
				categories: categories.data,
				products: products.data,
				totalCount: products.data.length,
			})
		} catch (error) {
			console.error(error)
			showError(error)
		}
	},
	createProduct: async ({ commit }, data) => {
		try {
			console.log(data)
			const res = await createProduct(data)

			console.log(res.created)

			commit('EXTEND_INTERNAL_PRODUCTS', { products: [res.created] })

			return res
		} catch (error) {
			console.error(error)
			showError(error)
		}
	},
	updateProduct: async ({ commit }, { productId, updateData }) => {
		try {
			console.log(productId)
			console.log(updateData)
			const res = await updateProduct(productId, updateData)

			console.log(res)

			commit('UPDATE_INTERNAL_PRODUCTS', res.updated)

			return res
		} catch (error) {
			console.error(error)
			showError(error)
		}
	},
	deleteProduct: async ({ commit }, data) => {
		try {
			const res = await deleteProduct(data)

			commit('REDUCE_INTERNAL_PRODUCTS', res.deletedId)
		} catch (error) {
			console.error(error)
			showError(error)
		}
	},
	// extendProducts: async function ({ commit }, data) {
	// 	try {
	// 		const response = await getByCategory(data)
	// 		commit('EXTEND_INTERNAL_PRODUCTS', response.data)
	// 	} catch (error) {
	// 		showError(error)
	// 	}
	// },
	resetProducts({ commit }) {
		commit('SET_INTERNAL_PRODUCTS', { products: [], totalCount: 0, categories: [] })
		// commit('SET_INTERNAL_PRODUCT_CATEGORIES', [])
	},
	setEditProduct({ commit }, product) {
		commit('SET_EDIT_PRODUCT', product)
	},
	setEditProductRef({ commit }, product) {
		commit('SET_EDIT_PRODUCT_REF', product)
	},
	sortProducts({ commit, state }, { orderedProducts, category }) {
		let count = 1
		orderedProducts.forEach((p) => {
			p.sortIndex = count
			count++
		})

		orderProductsApi(orderedProducts, category)

		const nonSortedProducts = state.internalProducts.filter((p) => p.category !== category)

		commit('SET_INTERNAL_PRODUCTS', {
			categories: state.categories,
			products: orderedProducts.concat(nonSortedProducts),
			totalCount: state.totalCount,
		})
	},
	setSelectedVariant({ commit }, variantHexValue) {
		commit('SET_SELECTED_VARIANT', variantHexValue)
	},
}

const mutations = {
	updateField,
	SET_INTERNAL_PRODUCTS: function (state, payload) {
		state.categories = payload.categories
		state.internalProducts = payload.products
		state.totalCount = payload.totalCount
	},
	EXTEND_INTERNAL_PRODUCTS: function (state, payload) {
		state.internalProducts = state.internalProducts.concat(payload.products)
		// state.totalCount = payload.totalCount
	},
	UPDATE_INTERNAL_PRODUCTS: function (state, updatedProduct) {
		console.log('udate internal')
		const products = state.internalProducts.map((p) => {
			if (p._id === updatedProduct._id) {
				return updatedProduct
			}
			return p
		})
		state.internalProducts = [...products]
		console.log(state.internalProducts)
	},
	REDUCE_INTERNAL_PRODUCTS: function (state, deletedId) {
		const idx = state.internalProducts.map((ip) => ip._id).indexOf(deletedId)
		if (idx >= 0) state.internalProducts.splice(idx, 1)
	},
	SET_EDIT_PRODUCT: function (state, product) {
		state.editProduct = { ...product }
	},
	SET_EDIT_PRODUCT_REF: function (state, product) {
		state.editProductRef = {
			colorImages: product.colorImages,
			colors: product.colors,
			images: product.images,
			mainImage: product.mainImage,
			sizes: product.sizes,
			variants: product.variants,
			// colorVariants: product.colorVariants, // ???
		}
	},
	SET_SELECTED_VARIANT: function (state, variant) {
		state.selectedVariant = variant
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
