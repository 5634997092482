import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'
import {
	getAll,
	getById,
	update as updateCustomer,
	syncSevdeskContacts,
	create as createCustomer,
	getCustomerDataById,
} from '@/api/customers.api'

const state = {
	customers: [],
	currentCustomer: {},
}

const getters = {
	getField,
	getCustomers: (state) => {
		return state.customers
	},
}

const actions = {
	// { q, perPage, page, sortBy, sortDesc, role, plan, status }
	fetchCustomers: async function ({ commit }, queryData) {
		try {
			const response = await getAll(queryData)
			commit('SET_CUSTOMERS', response.customers)
			return {
				data: {
					users: response.customers,
					total: response.total,
				},
			}
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	fetchCustomer: async function ({ commit }, customerId) {
		try {
			const response = await getById(customerId)
			commit('SET_CURRENT_CUSTOMER', response.customer)
			return response
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	updateCustomer: async function ({ state, dispatch, commit }) {
		try {
			const response = await updateCustomer(state.currentCustomer._id, state.currentCustomer)
			return response
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	newCustomer: async function ({ state, dispatch, commit }, data) {
		try {
			const response = await createCustomer(data)
			return response
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	syncSevdeskUsers: async () => {
		try {
			const promise = syncSevdeskContacts()

			return promise
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
}

const mutations = {
	updateField,
	SET_CUSTOMERS: function (state, payload) {
		state.customers = payload
	},
	SET_CURRENT_CUSTOMER: function (state, payload) {
		state.currentCustomer = payload
	},
	// SET_SEVDESK_USERS: function(state, payload) {
	// 	state.sevdeskUsers = payload
	// },
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
