import httpClient from './httpClient'

const END_POINT = process.env.VUE_APP_BASE_URL + '/trello'

const addCard = ({ requestId, category }) => {
	return httpClient.post(END_POINT + '/add-card', { requestId, category })
}
const changeCard = ({ requestId, category }) => {
	return httpClient.post(END_POINT + '/change-card', { requestId, category })
}

export { addCard, changeCard }
