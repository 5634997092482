import httpClient from './httpClient'

const END_POINT = process.env.VUE_APP_BASE_URL + '/auth'

const forgotPassword = (email) => {
	return httpClient.post(END_POINT + '/forgot-password', { email })
}

const resetPassword = (password, passwordRepeat, resetToken) => {
	return httpClient.post(END_POINT + '/reset-password', { password, passwordRepeat, resetToken })
}

const getOneTimeToken = () => {
	return httpClient.get(END_POINT + '/one-time-token')
}

export { forgotPassword, resetPassword, getOneTimeToken }
