import httpClient from './httpClient'

const END_POINT = process.env.VUE_APP_BASE_URL + '/customeraddresses'

const getAllByCustomer = id => {
	return httpClient.get(END_POINT + '/' + id)
}

const newAddress = (data)=> {
	return httpClient.post(END_POINT + '/new', {data})
}

const updateAddress = (data)=> {
	return httpClient.put(END_POINT + '/update/' + data._id, {data})
}

const deleteAddress = (id)=> {
	console.log(id)
	return httpClient.put(END_POINT + '/delete/' + id)
}

export { getAllByCustomer, newAddress, deleteAddress, updateAddress }
