import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'
import { fetchAllFiles as fetchAllFilesApi, uploadAccountFile, simpleUpload } from '@/api/utils.api'
import { addFile } from '@/api/requests.api'

const state = {
	files: [],
}

const getters = {
	getField,
}

const actions = {
	fetchFiles: async function ({ commit }, query) {
		try {
			const promise = fetchAllFilesApi(query)
			promise.then((res) => {
				if (res && res.files) commit('SET_FILES', res.files)
			})
			return promise
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error && error.message ? error.message : error,
				type: 'error',
			})
		}
	},
	async simpleUpload({ commit }, { file, fileName }) {
		const promise = simpleUpload(file, fileName)

		promise.then((res) => {
			console.log(res)
		})

		return promise
	},
	async uploadAccountFile({ commit }, { file }) {
		const upload = await uploadAccountFile(file)

		commit('ADD_FILE', {
			_id: upload._id,
			name: upload.filename,
			label: upload.label,
			url: upload.url,
			type: 'upload',
			width: upload.width,
			height: upload.height,
			createdAt: new Date(),
		})

		return upload
	},
	async addFileToRequest({ state }, { requestId, fileId }) {
		if (!state) console.log(state)
		await addFile(requestId, fileId)
	},
}

const mutations = {
	updateField,
	SET_FILES: function (state, payload) {
		state.files = payload
	},
	ADD_FILE: function (state, { name, label, url, type, width, height }) {
		state.files.push({ name, label, url, type, width, height })
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
