import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'
import { getAll, create, update, deleteById, getById, getSevdeskUsers } from '@/api/employees.api'

const state = {
	employees: [],
	sevdeskUsers: [],
}

const getters = {
	getField,
	getEmployees: state => {
		return state.employees
	},
}

const actions = {
	// { q, perPage, page, sortBy, sortDesc, role, plan, status }
	fetchEmployees: async function({ commit }, queryData) {
		try {
			const response = await getAll(queryData)
			commit('SET_EMPLOYEES', response.data)
			return {
				data: {
					users: response.users,
					total: response.total,
				},
			}
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	fetchUser: async function({ commit }, userId) {
		try {
			const response = await getById(userId)
			return response
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	fetchSevdeskUsers: async function({ commit }) {
		try {
			const response = await getSevdeskUsers()
			console.log(response)
			commit('SET_SEVDESK_USERS', response)
			return response
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	addUser: async function({ commit }, userData) {
		try {
			await create(userData)
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	updateUser: async function({ commit }, userData) {
		try {
			const response = await update(userData._id, userData)
			return response
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	deleteUser: async function({ commit }, userId) {
		try {
			await deleteById(userId)
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
}

const mutations = {
	updateField,
	SET_EMPLOYEES: function(state, payload) {
		state.employees = payload
	},
	SET_SEVDESK_USERS: function(state, payload) {
		state.sevdeskUsers = payload
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
