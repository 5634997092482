import httpClient from './httpClient'

const END_POINT = process.env.VUE_APP_BASE_URL + '/customers'

const getAll = (params) => {
	return httpClient.get(END_POINT)
}

const getById = (id) => {
	return httpClient.get(`${END_POINT}/${id}`)
}

const update = (id, data) => {
	return httpClient.put(`${END_POINT}/${id}`, { data })
}

const create = (data) => {
	return httpClient.post(END_POINT, { data })
}
const syncSevdeskContacts = () => {
	return httpClient.get(`${END_POINT}/sevdesk-sync`)
}

export { getAll, getById, syncSevdeskContacts, update, create }
