import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'
import { addCard, changeCard } from '@/api/trello.api'

const state = {}

const getters = {
	getField,
}

const actions = {
	async addCard({ commit }, { requestId, category }) {
		const promise = addCard({ requestId, category })

		promise
			.then((res) => {
				console.log(res)
			})
			.catch((err) => {
				console.error(err)
			})

		return promise
	},
	async changeCard({ commit }, { requestId, category }) {
		const promise = changeCard({ requestId, category })

		promise
			.then((res) => {
				console.log(res)
			})
			.catch((err) => {
				console.error(err)
			})

		return promise
	},
}

const mutations = {
	updateField,
	// SET_FILES: function(state, payload) {
	// 	state.files = payload
	// },
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
