import httpClient from './httpClient'

const END_POINT = process.env.VUE_APP_BASE_URL + '/employees'
const SEVDESK_END_POINT = process.env.VUE_APP_BASE_URL + '/sevdesk'

const getAll = params => {
	return httpClient.get(END_POINT, { params })
}

const getById = id => {
	return httpClient.get(END_POINT + '/' + id)
}

const create = data => {
	return httpClient.post(END_POINT, { data })
}

const update = (id, data) => {
	return httpClient.put(END_POINT + '/' + id, { data })
}

const deleteById = userId => {
	return httpClient.delete(END_POINT + '/' + userId)
}

const getSevdeskUsers = () => {
	return httpClient.get(SEVDESK_END_POINT + '/users')
}

export { getAll, getById, create, update, deleteById, getSevdeskUsers }
