import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

// wercbrain
import auth from './auth.store'
import dictionary from './dictionary.store'
import requests from './requests.store'
import internalProducts from './internalProducts.store'
import externalProducts from './externalProducts.store'
import employees from './employees.store'
import customers from './customers.store'
import addresses from './addresses.store'
import files from './files.store'
import calcVariables from './calcVariables.store'
import trello from './trello.store'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		app,
		appConfig,
		verticalMenu,
		'app-ecommerce': ecommerceStoreModule,
		Auth: auth,
		Dictionary: dictionary,
		Requests: requests,
		InternalProducts: internalProducts,
		ExternalProducts: externalProducts,
		Employees: employees,
		Customers: customers,
		Addresses: addresses,
		Files: files,
		CalcVariables: calcVariables,
		Trello: trello,
	},
	strict: process.env.DEV,
})
